import snakecaseKeys from 'snakecase-keys'
import axiosIns from '@/plugins/axios'

export default {
  getStoreCustomFields() {
    return axiosIns.get('/v2/employee/user_management/store_custom_fields')
  },

  createStoreCustomField(params) {
    return axiosIns.post(
      '/v2/employee/user_management/store_custom_fields',
      snakecaseKeys({
        storeCustomField: params,
      }),
    )
  },

  updateStoreCustomField(id, params) {
    return axiosIns.put(
      `/v2/employee/user_management/store_custom_fields/${id}`,
      snakecaseKeys({
        storeCustomField: params,
      }),
    )
  },

  deleteStoreCustomField(id) {
    return axiosIns.delete(`/v2/employee/user_management/store_custom_fields/${id}`)
  },
}
