import { reactive, computed } from '@vue/composition-api'

export default function useUserFormState(props) {
  const state = reactive({
    form: {
      id: null,
      name: '',
      role: '',
      profileImage: '',
      preview: null,
      imageFile: null,
    },
    flags: [],
    customFields: [],
    isSubmitting: false,
    isValid: false,
    originalUserSystemFlaggings: [],
  })

  const isNew = computed(() => !props.user.id)
  const isPersisted = computed(() => !!props.user.id)
  const formTitle = computed(() => (isNew.value ? '新規ユーザー' : 'ユーザー編集'))
  const formSubmitText = computed(() => (isNew.value ? '作成' : '更新'))
  const userRoleText = computed(() => ({
    cast: 'キャスト',
    waiter: 'スタッフ',
    alliance: 'アライアンス',
  }[props.role]))

  const initialize = () => {
    // フォームデータの初期化
    const { id, attributes } = props.user
    state.form.id = id
    state.form.name = attributes.name || ''
    state.form.role = props.role
    state.form.profileImage = attributes.profileImage || ''
    state.form.preview = null

    // profileImageがある場合、仮のファイルオブジェクトを作成
    if (attributes.profileImage) {
      state.form.imageFile = new File([], 'profile-image', { type: 'image/jpeg' })
    } else {
      state.form.imageFile = null
    }

    // 元のラベル情報を保持
    state.originalUserSystemFlaggings = attributes.userSystemFlaggings?.data || []

    // ラベルの初期化
    const userSystemFlaggings = attributes.userSystemFlaggings?.data || []
    state.flags = userSystemFlaggings
      .map(flagging => {
        const flag = props.userSystemFlags.find(f => Number(f.id) === Number(flagging.attributes.userSystemFlagId))

        return flag ? {
          ...flag,
          flaggingId: flagging.id,
        } : null
      })
      .filter(Boolean)

    // カスタムフィールドの初期化
    const userCustomFieldValues = attributes.userCustomFieldValues?.data || []
    state.customFields = userCustomFieldValues
      .map(value => ({
        id: Number(value.id),
        storeCustomFieldId: Number(value.attributes.storeCustomFieldId),
        value: value.attributes.value,
      }))

    state.isSubmitting = false
    state.isValid = false
  }

  // コンポーネントのマウント時に初期化
  initialize()

  return {
    state,
    isNew,
    isPersisted,
    formTitle,
    formSubmitText,
    userRoleText,
    initialize,
  }
}
