<template>
  <v-dialog
    v-model="dialog"
    max-width="500px"
  >
    <v-card>
      <v-card-title>{{ isPersisted ? 'プロパティを編集' : '新規プロパティを追加' }}</v-card-title>
      <v-card-text>
        <v-form ref="propertyForm">
          <v-text-field
            v-model="formData.name"
            label="プロパティ名"
            required
          />
          <v-row>
            <v-col cols="6">
              <v-select
                v-model="formData.type"
                :items="propertyTypes"
                label="プロパティタイプ"
                required
                :disabled="isPersisted"
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model.number="formData.position"
                label="表示順序"
                type="number"
                persistent-hint
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          text
          @click="onCancel"
        >
          キャンセル
        </v-btn>
        <v-btn
          color="primary"
          :loading="isSubmitting"
          :disabled="isSubmitting"
          @click="onSubmit"
        >
          {{ isPersisted ? '更新' : '追加' }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  reactive, computed, watch, ref,
} from '@vue/composition-api'

export default {
  name: 'PropertyFormDialog',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    property: {
      type: Object,
      default: () => null,
    },
    role: {
      type: String,
      required: true,
      default: 'cast',
    },
  },
  setup(props, { emit }) {
    const dialog = computed({
      get: () => props.value,
      set: value => emit('input', value),
    })

    const isPersisted = computed(() => !!props.property)

    const propertyTypes = [
      { text: 'テキスト', value: 'text' },
      { text: '数値', value: 'number' },
      { text: 'チェックボックス', value: 'boolean' },
    ]

    const formData = reactive({
      name: '',
      type: 'text',
      position: 0,
    })

    const isSubmitting = ref(false)

    const resetForm = () => {
      formData.name = ''
      formData.type = 'text'
      formData.position = 0
    }

    // プロパティが渡された場合（編集モード）、フォームデータを初期化
    watch(() => props.property, newVal => {
      if (newVal) {
        formData.name = newVal.name || ''
        formData.type = newVal.type || 'text'
        formData.position = newVal.position || 0
      } else {
        resetForm()
      }
    }, { immediate: true })

    const onSubmit = async () => {
      if (isSubmitting.value) return

      isSubmitting.value = true
      const callback = () => {
        isSubmitting.value = false
      }

      if (isPersisted.value) {
        // 編集モード
        emit('update', {
          text: formData.name,
          position: formData.position,
          callback,
        })
      } else {
        // 新規作成モード
        const value = formData.name.toLowerCase().replace(/\s+/g, '_')
        emit('submit', {
          text: formData.name,
          value,
          type: formData.type,
          position: formData.position,
          role: props.role,
          callback,
        })
      }
      if (!isPersisted.value) {
        resetForm()
      }
    }

    const onCancel = () => {
      dialog.value = false
      if (!isPersisted.value) {
        resetForm()
      }
    }

    return {
      dialog,
      isPersisted,
      formData,
      propertyTypes,
      isSubmitting,
      onSubmit,
      onCancel,
    }
  },
}
</script>
