<template>
  <v-card>
    <v-card-title>
      {{ formTitle }}
      <v-spacer />

      <v-dialog
        v-if="isPersisted"
        width="400"
      >
        <template #activator="{ on: deleteOn, attrs: deleteAttrs }">
          <v-btn
            icon
            :ripple="false"
            v-bind="deleteAttrs"
            v-on="deleteOn"
          >
            <v-icon>
              {{ icons.mdiTrashCanOutline }}
            </v-icon>
          </v-btn>
        </template>
        <template #default="deleteConfirmationMenu">
          <v-card>
            <v-card-title>
              削除の確認
            </v-card-title>

            <v-card-text>
              削除を実行してもよろしいですか
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                :ripple="false"
                @click="onCancel(); deleteConfirmationMenu.value = false"
              >
                キャンセル
              </v-btn>
              <v-btn
                :loading="isSubmitting"
                :disabled="isSubmitting"
                :ripple="false"
                color="error"
                @click="onDelete(deleteConfirmationMenu)"
              >
                同意の上で削除
              </v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>
    </v-card-title>

    <v-card-text>
      <v-form
        ref="form"
        v-model="formValid"
      >
        <v-row>
          <v-col cols="12">
            <div class="d-flex align-center mb-4">
              <v-avatar
                size="64"
                color="secondary"
                class="mr-4"
              >
                <v-img
                  v-if="preview"
                  :src="preview"
                />
                <v-img
                  v-else-if="formData.profileImage"
                  :src="formData.profileImage"
                />
                <span
                  v-else-if="formData.name"
                  class="text-h6 font-weight-medium"
                >
                  {{ formData.name.substring(0, 3) }}
                </span>
                <v-icon
                  v-else
                  size="32"
                >
                  mdi-account
                </v-icon>
              </v-avatar>
              <v-file-input
                v-model="imageFile"
                accept="image/*"
                label="プロフィール画像"
                hide-details
                :clearable="false"
                class="flex-grow-1"
                @change="handleImageChange"
              >
                <template #append>
                  <v-btn
                    v-if="imageFile"
                    icon
                    small
                    :ripple="false"
                    @click.stop="handleImageChange(null)"
                  >
                    <v-icon>
                      {{ icons.mdiClose }}
                    </v-icon>
                  </v-btn>
                </template>
              </v-file-input>
            </div>
          </v-col>

          <v-col cols="12">
            <v-text-field
              v-model="formData.name"
              label="名前"
              :rules="[v => !!v || '名前は必須です']"
              required
            />
          </v-col>

          <v-col cols="12">
            <v-text-field
              :value="userRoleText"
              disabled
              label="ロール"
            />
          </v-col>

          <v-col cols="12">
            <v-combobox
              :value="selectedFlags"
              :items="userSystemFlags"
              item-text="attributes.name"
              return-object
              label="ラベル"
              no-data-text="利用可能なデータがありません"
              multiple
              chips
              deletable-chips
              hide-selected
              hint="新しいラベルを作成するには、ラベル名を入力してください"
              persistent-hint
              class="mb-2"
              @input="onComboBoxInput"
            >
              <template #selection="{ item }">
                <v-chip
                  close
                  @click:close="removeFlag(item)"
                >
                  {{ item.attributes && item.attributes.name }}
                  <v-icon
                    v-if="item.isNew"
                    x-small
                    class="ml-2"
                  >
                    {{ icons.mdiPlus }}
                  </v-icon>
                </v-chip>
              </template>
            </v-combobox>
          </v-col>

          <!-- カスタム属性 -->
          <v-col
            v-for="header in customHeaders"
            :key="header.value"
            cols="12"
            sm="6"
          >
            <v-checkbox
              v-if="header.type === 'boolean'"
              :label="header.text"
              :input-value="(customFieldValues.find(field => Number(field.storeCustomFieldId) === Number(header.value)) || {}).value === 'true'"
              @change="updateCustomFieldValue(header.value, $event ? 'true' : 'false')"
            />
            <v-text-field
              v-else
              :label="header.text"
              :type="header.type"
              :value="(customFieldValues.find(field => Number(field.storeCustomFieldId) === Number(header.value)) || {}).value || ''"
              @input="updateCustomFieldValue(header.value, $event)"
            />
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="blue darken-1"
        text
        :ripple="false"
        @click="onCancel"
      >
        キャンセル
      </v-btn>
      <v-btn
        color="primary"
        :loading="isSubmitting"
        :disabled="!formValid || isSubmitting"
        :ripple="false"
        @click="onSubmit"
      >
        {{ formSubmitText }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {
  computed,
  onMounted,
  ref,
} from '@vue/composition-api'
import { mdiTrashCanOutline, mdiPlus, mdiClose } from '@mdi/js'
import ability from '@/plugins/acl/ability'
import useUserFormState from '../composables/useUserFormState'
import useUserFormUI from '../composables/useUserFormUI'

export default {
  name: 'UserForm',
  props: {
    role: {
      type: String,
      required: true,
      default: '',
    },
    user: {
      type: Object,
      default: () => ({
        id: null,
        attributes: {
          name: '',
          role: '',
          profileImage: '',
          userSystemFlaggings: {
            data: [],
          },
          userCustomFieldValues: {
            data: [],
          },
        },
      }),
    },
    userSystemFlags: {
      type: Array,
      default: () => [],
    },
    customHeaders: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const form = ref(null)
    const formState = useUserFormState(props)
    const formUI = useUserFormUI(formState)

    const onSubmit = async () => {
      if (!await form.value.validate() || formState.state.isSubmitting) return

      formState.state.isSubmitting = true
      const callback = () => {
        formState.state.isSubmitting = false
      }

      emit('submit', {
        id: formState.state.form.id,
        name: formState.state.form.name,
        profileImage: formState.state.form.imageFile || formState.state.form.profileImage,
        userSystemFlaggingsAttributes: formUI.getFlagAttributes(),
        userCustomFieldValuesAttributes: formUI.getCustomFieldAttributes(),
        callback,
      })
    }

    const onDelete = dialog => {
      if (formState.state.isSubmitting) return

      // eslint-disable-next-line no-param-reassign
      dialog.value = false
      formState.state.isSubmitting = true
      const callback = () => {
        formState.state.isSubmitting = false
      }
      emit('delete', { id: formState.state.form.id, callback })
    }

    const onCancel = () => {
      formState.initialize()
      emit('cancel')
    }

    onMounted(() => {
      formState.initialize()
    })

    const canUseNewClosing = computed(() => {
      return ability.can('manage', 'eod_closing')
    })

    return {
      form,
      formValid: formState.state.isValid,
      isSubmitting: computed(() => formState.state.isSubmitting),
      formData: formState.state.form,
      preview: computed(() => formState.state.form.preview),
      imageFile: computed({
        get: () => formState.state.form.imageFile,
        set: value => formUI.handleImageChange(value),
      }),
      formTitle: formState.formTitle,
      formSubmitText: formState.formSubmitText,
      userRoleText: formState.userRoleText,
      isPersisted: formState.isPersisted,
      isNew: formState.isNew,
      selectedFlags: computed(() => formState.state.flags),
      customFieldValues: computed(() => formState.state.customFields),
      handleImageChange: formUI.handleImageChange,
      onSubmit,
      onDelete,
      onCancel,
      onComboBoxInput: formUI.handleFlagInput,
      removeFlag: formUI.removeFlag,
      updateCustomFieldValue: formUI.updateCustomFieldValue,
      canUseNewClosing,

      icons: {
        mdiTrashCanOutline,
        mdiPlus,
        mdiClose,
      },
    }
  },
}
</script>
