import snakecaseKeys from 'snakecase-keys'
import { serialize } from 'object-to-formdata'
import axiosIns from '@/plugins/axios'

export default {
  getUsers() {
    return axiosIns.get('/v2/employee/user_management/users')
  },

  createUser(params) {
    const userData = {
      name: params.name,
      role: params.role,
      profileImage: params.profileImage,
      userSystemFlaggingsAttributes: params.userSystemFlaggingsAttributes,
      userCustomFieldValuesAttributes: params.userCustomFieldValuesAttributes,
    }

    // プロフィール画像がFileオブジェクトの場合はFormDataを使用
    if (params.profileImage instanceof File) {
      const formData = serialize(snakecaseKeys({
        user: {
          ...userData,
          profileImage: undefined, // serializeではファイルを送信しない
        },
      }))
      formData.append('user[profile_image]', params.profileImage)

      return axiosIns.post('/v2/employee/user_management/users', formData)
    }

    // 通常のJSONリクエスト
    return axiosIns.post('/v2/employee/user_management/users', {
      user: snakecaseKeys(userData),
    })
  },

  updateUser(params) {
    const userData = {
      name: params.name,
      profileImage: params.profileImage,
      userSystemFlaggingsAttributes: params.userSystemFlaggingsAttributes,
      userCustomFieldValuesAttributes: params.userCustomFieldValuesAttributes,
    }

    // プロフィール画像がFileオブジェクトの場合はFormDataを使用
    if (params.profileImage instanceof File) {
      const formData = serialize(snakecaseKeys({
        user: {
          ...userData,
          profileImage: undefined, // serializeではファイルを送信しない
        },
      }))
      formData.append('user[profile_image]', params.profileImage)

      return axiosIns.put(`/v2/employee/user_management/users/${params.id}`, formData)
    }

    // 通常のJSONリクエスト（_destroyフラグは除外）
    return axiosIns.put(`/v2/employee/user_management/users/${params.id}`, {
      user: snakecaseKeys(userData, { exclude: ['_destroy'] }),
    })
  },

  deleteUser(id) {
    return axiosIns.delete(`/v2/employee/user_management/users/${id}`)
  },
}
