import { ref } from '@vue/composition-api'
import UserSystemFlagApi from '@/api/v2/employee/user-management/UserSystemFlag'

export default function useUserSystemFlags() {
  const userSystemFlags = ref([])

  // ラベルの一覧を取得
  const getUserSystemFlags = async () => {
    const res = await UserSystemFlagApi.getUserSystemFlags()
    if (res.data.status === 'success') {
      userSystemFlags.value = res.data.data.data
    }
  }

  return {
    userSystemFlags,
    getUserSystemFlags,
  }
}
