/* eslint-disable no-param-reassign */
export default function useUserFormUI(formState) {
  const handleImageChange = file => {
    if (!file) {
      formState.state.form.preview = null
      formState.state.form.imageFile = null
      formState.state.form.profileImage = null

      return
    }

    const reader = new FileReader()
    reader.onload = e => {
      formState.state.form.preview = e.target.result
      formState.state.form.imageFile = file
    }
    reader.readAsDataURL(file)
  }

  const handleFlagInput = flags => {
    if (!Array.isArray(flags)) return

    formState.state.flags = flags.map(flag => {
      if (flag && flag.type === 'userSystemFlag') {
        return flag
      }

      return {
        type: 'userSystemFlag',
        attributes: {
          name: flag,
        },
        isNew: true,
      }
    })
  }

  const removeFlag = flag => {
    formState.state.flags = formState.state.flags.filter(f => f.id !== flag.id)
  }

  const updateCustomFieldValue = (fieldId, value) => {
    const existingField = formState.state.customFields.find(
      field => Number(field.storeCustomFieldId) === Number(fieldId),
    )

    if (existingField) {
      existingField.value = value
    } else {
      formState.state.customFields = [
        ...formState.state.customFields,
        { storeCustomFieldId: fieldId, value },
      ]
    }
  }

  const getFlagAttributes = () => {
    // 元のラベル情報を取得
    const originalFlaggings = formState.state.originalUserSystemFlaggings || []
    const attributes = []

    // 削除対象のラベルを特定
    const selectedFlagIds = formState.state.flags
      .filter(f => !f.isNew)
      .map(f => Number(f.id))

    // 元のラベルで、現在選択されていないものを削除対象とする
    originalFlaggings.forEach(flagging => {
      if (!selectedFlagIds.includes(Number(flagging.attributes.userSystemFlagId))) {
        attributes.push({
          id: flagging.id,
          _destroy: true,
        })
      }
    })

    // 既存ラベルの新規紐付けと新規ラベルの作成＆紐付け
    formState.state.flags.forEach(flag => {
      if (flag.flaggingId) return

      if (flag.isNew) {
        attributes.push({
          userSystemFlagAttributes: {
            name: flag.attributes.name,
          },
        })
      } else {
        attributes.push({
          userSystemFlagId: flag.id,
        })
      }
    })

    // 新規ラベルのみの場合でも、attributesが空の配列にならないようにする
    return attributes
  }

  const getCustomFieldAttributes = () => {
    return formState.state.customFields.map(value => ({
      id: value.id,
      storeCustomFieldId: Number(value.storeCustomFieldId),
      value: value.value,
    }))
  }

  return {
    handleImageChange,
    handleFlagInput,
    removeFlag,
    updateCustomFieldValue,
    getFlagAttributes,
    getCustomFieldAttributes,
  }
}
