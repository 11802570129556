<template>
  <div>
    <v-row class="pb-5">
      <v-col
        v-for="user in users"
        :key="`user${user.id}`"
        md="3"
        sm="3"
        cols="4"
        class="my-2"
      >
        <div class="d-flex flex-column align-center mb-4">
          <v-hover v-slot="{ hover }">
            <v-avatar
              size="60"
              class="cursor-pointer"
              :class="{ 'elevation-4' : hover }"
              color="secondary"
              @click="$emit('edit', user)"
            >
              <v-img
                v-if="user.attributes.profileImage"
                :src="user.attributes.profileImage"
              />
              <span
                v-else
                class="text-caption font-weight-medium"
              >
                {{ user.attributes.name.substring(0, 3) }}
              </span>
            </v-avatar>
          </v-hover>

          <div class="mt-2 text-center">
            {{ user.attributes.name }}
          </div>

          <v-chip-group
            dense
            column
            class="mt-1"
          >
            <v-chip
              v-for="flagging in (user.attributes.userSystemFlaggings && user.attributes.userSystemFlaggings.data) || []"
              :key="flagging.id"
              small
            >
              {{ findSystemFlagName(flagging.attributes.userSystemFlagId) }}
            </v-chip>
          </v-chip-group>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'UserGrid',
  props: {
    users: {
      type: Array,
      required: true,
      default: () => [],
    },
    userSystemFlags: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  setup(props) {
    const findSystemFlagName = flagId => {
      const flag = props.userSystemFlags.find(f => Number(f.id) === Number(flagId))

      return flag?.attributes?.name || ''
    }

    return {
      findSystemFlagName,
    }
  },
}
</script>

<style scoped>
.cursor-pointer {
  cursor: pointer;
}
</style>
