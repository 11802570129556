<template>
  <v-dialog
    v-model="dialog"
    max-width="400px"
  >
    <v-card>
      <v-card-title>プロパティを削除</v-card-title>
      <v-card-text>
        <p>「{{ propertyName }}」を削除してもよろしいですか？</p>
        <p class="text-caption red--text">
          この操作は取り消せません。
        </p>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          text
          @click="onCancel"
        >
          キャンセル
        </v-btn>
        <v-btn
          color="error"
          :disabled="isSubmitting"
          :loading="isSubmitting"
          @click="onConfirm"
        >
          削除
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { computed, ref } from '@vue/composition-api'

export default {
  name: 'DeletePropertyConfirm',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    property: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props, { emit }) {
    const dialog = computed({
      get: () => props.value,
      set: value => emit('input', value),
    })

    const propertyName = computed(() => props.property?.name || '')
    const isSubmitting = ref(false)

    const onConfirm = () => {
      if (isSubmitting.value) return

      isSubmitting.value = true
      const callback = () => {
        isSubmitting.value = false
      }
      emit('confirm', { callback })
    }

    const onCancel = () => {
      dialog.value = false
    }

    return {
      dialog,
      propertyName,
      isSubmitting,
      onConfirm,
      onCancel,
    }
  },
}
</script>
