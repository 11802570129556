var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"user-list-table",attrs:{"headers":_vm.headers,"items":_vm.transformedItems,"footer-props":{ itemsPerPageText: '行/ページ:' },"no-data-text":"データがありません","loading-text":"読込中..."},on:{"click:row":function($event){return _vm.$emit('edit', $event)}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"transparent"}},[_c('v-spacer'),_c('v-btn',{attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.$emit('add-property')}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiPlus)+" ")]),_vm._v(" プロパティを追加 ")],1)],1)]},proxy:true},_vm._l((_vm.customHeaders),function(header){return {key:("header." + (header.value)),fn:function(){return [_c('span',{key:("header-text-" + (header.value))},[_vm._v(" "+_vm._s(header.text)+" "),_c('v-menu',{attrs:{"offset-y":"","left":"","close-on-content-click":true,"content-class":"custom-header-menu"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-1 custom-header-menu-btn",staticStyle:{"margin-top":"-3px"},attrs:{"icon":"","x-small":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiDotsVertical)+" ")])],1)]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[_c('v-subheader',[_vm._v("操作")]),_c('v-list-item',{on:{"click":function($event){$event.stopPropagation();return _vm.$emit('edit-property', header)}}},[_c('v-list-item-icon',{staticClass:"mr-2"},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiPencilOutline)+" ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("編集")])],1)],1),_c('v-list-item',{on:{"click":function($event){$event.stopPropagation();return _vm.$emit('delete-property', header)}}},[_c('v-list-item-icon',{staticClass:"mr-2"},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiTrashCanOutline)+" ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("削除")])],1)],1)],1)],1)],1)]},proxy:true}}),{key:"item.attributes.profileImage",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',{attrs:{"size":"40","color":"secondary"}},[(item.attributes.profileImage)?_c('v-img',{attrs:{"src":item.attributes.profileImage}}):_c('span',{staticClass:"text-caption font-weight-medium"},[_vm._v(" "+_vm._s(item.attributes.name.substring(0, 3))+" ")])],1)]}},{key:"item.attributes.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.attributes.name)+" ")]}},{key:"item.userSystemFlaggingFlags",fn:function(ref){
var item = ref.item;
return [_c('v-chip-group',{attrs:{"dense":"","column":""}},_vm._l((item.userSystemFlaggingFlags),function(flag){return _c('v-chip',{key:flag.id,attrs:{"small":""}},[_vm._v(" "+_vm._s(flag.name)+" ")])}),1)]}},_vm._l((_vm.customHeaders),function(header){return {key:("item." + (header.value)),fn:function(ref){
var item = ref.item;
return [(header.type === 'boolean')?_c('v-checkbox',{key:("checkbox-" + (header.value)),staticClass:"ma-0 pa-0",attrs:{"input-value":item[header.value] === 'true',"disabled":"","dense":"","hide-details":""}}):_c('span',{key:("text-" + (header.value))},[_vm._v(" "+_vm._s(item[header.value])+" ")])]}}}),{key:"item.actions",fn:function(){return [_c('v-icon',{attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiChevronRight)+" ")])]},proxy:true}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }