import { ref, computed } from '@vue/composition-api'
import StoreCustomFieldApi from '@/api/v2/employee/user-management/StoreCustomField'
import { handleApiError } from '@/utils/errorHandler'

export default function useStoreCustomFields(vm, currentRole) {
  const customFields = ref([])
  const customHeaders = ref([])

  const filteredCustomHeaders = computed(() => {
    return customHeaders.value.filter(header => header.role === currentRole.value)
  })

  const getCustomFields = async () => {
    const res = await StoreCustomFieldApi.getStoreCustomFields()

    if (res.data.status === 'success') {
      customFields.value = res.data.data.data
      customHeaders.value = customFields.value
        .map(field => ({
          text: field.attributes.name,
          value: field.id,
          type: field.attributes.fieldType,
          position: field.attributes.position || 0,
          role: field.attributes.role,
        }))
        .sort((a, b) => a.position - b.position)
    }
  }

  const addCustomField = async ({
    text, type, position = 0, role = 'cast', callback,
  }) => {
    try {
      const res = await StoreCustomFieldApi.createStoreCustomField({
        name: text,
        field_type: type,
        position,
        role,
      })

      if (res.data.status === 'success') {
        await getCustomFields()
        vm.$toast.success('プロパティを追加しました')

        return true
      }
      if (res.data.status === 'error') {
        vm.$toast.error('プロパティの追加に失敗しました')
        vm.$toast.error(res.data.message.join('\n'))

        return false
      }

      return false
    } catch (error) {
      handleApiError(error, { $toast: vm.$toast })

      return false
    } finally {
      callback()
    }
  }

  const updateCustomField = async (id, { text, position, callback }) => {
    try {
      const res = await StoreCustomFieldApi.updateStoreCustomField(
        id,
        {
          name: text,
          position,
        },
      )

      if (res.data.status === 'success') {
        await getCustomFields()
        vm.$toast.success('プロパティを更新しました')

        return true
      }
      if (res.data.status === 'error') {
        vm.$toast.error('プロパティの更新に失敗しました')
        vm.$toast.error(res.data.message.join('\n'))

        return false
      }

      return false
    } catch (error) {
      handleApiError(error, { $toast: vm.$toast })

      return false
    } finally {
      callback()
    }
  }

  const deleteCustomField = async (id, { callback }) => {
    try {
      const res = await StoreCustomFieldApi.deleteStoreCustomField(id)

      if (res.data.status === 'success') {
        await getCustomFields()
        vm.$toast.success('プロパティを削除しました')

        return true
      }
      if (res.data.status === 'error') {
        vm.$toast.error('プロパティの削除に失敗しました')
        vm.$toast.error(res.data.message.join('\n'))

        return false
      }

      return false
    } catch (error) {
      handleApiError(error, { $toast: vm.$toast })

      return false
    } finally {
      callback()
    }
  }

  return {
    customFields,
    customHeaders,
    filteredCustomHeaders,
    getCustomFields,
    addCustomField,
    updateCustomField,
    deleteCustomField,
  }
}
