<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="transformedItems"
      :footer-props="{ itemsPerPageText: '行/ページ:' }"
      no-data-text="データがありません"
      loading-text="読込中..."
      class="user-list-table"
      @click:row="$emit('edit', $event)"
    >
      <template #top>
        <v-toolbar
          flat
          color="transparent"
        >
          <v-spacer />
          <v-btn
            small
            color="primary"
            @click="$emit('add-property')"
          >
            <v-icon left>
              {{ icons.mdiPlus }}
            </v-icon>
            プロパティを追加
          </v-btn>
        </v-toolbar>
      </template>

      <template
        v-for="header in customHeaders"
        #[`header.${header.value}`]
      >
        <span :key="`header-text-${header.value}`">
          {{ header.text }}
          <v-menu
            offset-y
            left
            :close-on-content-click="true"
            content-class="custom-header-menu"
          >
            <template #activator="{ on, attrs }">
              <v-btn
                icon
                x-small
                class="ml-1 custom-header-menu-btn"
                style="margin-top: -3px;"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon small>
                  {{ icons.mdiDotsVertical }}
                </v-icon>
              </v-btn>
            </template>
            <v-list dense>
              <v-subheader>操作</v-subheader>
              <v-list-item @click.stop="$emit('edit-property', header)">
                <v-list-item-icon class="mr-2">
                  <v-icon small>
                    {{ icons.mdiPencilOutline }}
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>編集</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item @click.stop="$emit('delete-property', header)">
                <v-list-item-icon class="mr-2">
                  <v-icon small>
                    {{ icons.mdiTrashCanOutline }}
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>削除</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
        </span>
      </template>

      <template #[`item.attributes.profileImage`]="{ item }">
        <v-avatar
          size="40"
          color="secondary"
        >
          <v-img
            v-if="item.attributes.profileImage"
            :src="item.attributes.profileImage"
          />
          <span
            v-else
            class="text-caption font-weight-medium"
          >
            {{ item.attributes.name.substring(0, 3) }}
          </span>
        </v-avatar>
      </template>

      <template #[`item.attributes.name`]="{ item }">
        {{ item.attributes.name }}
      </template>

      <template #[`item.userSystemFlaggingFlags`]="{ item }">
        <v-chip-group
          dense
          column
        >
          <v-chip
            v-for="flag in item.userSystemFlaggingFlags"
            :key="flag.id"
            small
          >
            {{ flag.name }}
          </v-chip>
        </v-chip-group>
      </template>

      <template
        v-for="header in customHeaders"
        #[`item.${header.value}`]="{ item }"
      >
        <v-checkbox
          v-if="header.type === 'boolean'"
          :key="`checkbox-${header.value}`"
          :input-value="item[header.value] === 'true'"
          disabled
          dense
          hide-details
          class="ma-0 pa-0"
        />
        <span
          v-else
          :key="`text-${header.value}`"
        >
          {{ item[header.value] }}
        </span>
      </template>

      <template #[`item.actions`]>
        <v-icon small>
          {{ icons.mdiChevronRight }}
        </v-icon>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import {
  computed,
} from '@vue/composition-api'
import {
  mdiPlus,
  mdiPencilOutline,
  mdiDotsVertical,
  mdiTrashCanOutline,
  mdiChevronRight,
} from '@mdi/js'

export default {
  name: 'UserList',
  props: {
    users: {
      type: Array,
      default: () => [],
      required: true,
    },
    customHeaders: {
      type: Array,
      default: () => [],
    },
    userSystemFlags: {
      type: Array,
      default: () => [],
      required: true,
    },
  },
  setup(props) {
    const findCustomFieldValue = (item, fieldId) => {
      const fieldValue = item.attributes.userCustomFieldValues?.data?.find(value => Number(value.attributes.storeCustomFieldId) === Number(fieldId))

      return fieldValue?.attributes?.value || ''
    }

    const findSystemFlagName = flagId => {
      const flag = props.userSystemFlags.find(f => Number(f.id) === Number(flagId))

      return flag?.attributes?.name || ''
    }

    const transformedItems = computed(() => {
      return props.users.map(user => {
        const transformed = {
          ...user,
          userSystemFlaggingFlags: (user.attributes.userSystemFlaggings?.data || []).map(flagging => ({
            id: flagging.id,
            name: findSystemFlagName(flagging.attributes.userSystemFlagId),
          })),
        }

        // カスタムヘッダーの値を追加
        props.customHeaders.forEach(header => {
          transformed[header.value] = findCustomFieldValue(user, header.value)
        })

        return transformed
      })
    })

    const headers = computed(() => [
      {
        text: 'アイコン',
        value: 'attributes.profileImage',
        sortable: false,
        width: '80',
        align: 'center',
        class: 'fixed-cell-icon',
        cellClass: 'fixed-cell-icon',
      },
      {
        text: '名前',
        value: 'attributes.name',
        sortable: true,
        width: '200',
        class: 'fixed-cell-name',
        cellClass: 'fixed-cell-name',
      },
      {
        text: 'ラベル',
        value: 'userSystemFlaggingFlags',
        sortable: false,
        width: '200',
      },
      ...(props.customHeaders || []),
      {
        text: '',
        value: 'actions',
        sortable: false,
        width: '40',
        align: 'end',
      },
    ])

    return {
      headers,
      transformedItems,
      findCustomFieldValue,
      findSystemFlagName,
      icons: {
        mdiPlus,
        mdiPencilOutline,
        mdiDotsVertical,
        mdiTrashCanOutline,
        mdiChevronRight,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@core/preset/preset/mixins.scss';

@include theme--child(user-list-table) using ($material) {
  .fixed-cell-icon {
    position: sticky !important;
    position: -webkit-sticky !important;
    left: 0;
    z-index: 3;
    background: map-deep-get($material, 'app-bar');
    width: 80px !important;
  }

  .fixed-cell-name {
    position: sticky !important;
    position: -webkit-sticky !important;
    left: 80px;
    z-index: 3;
    background: map-deep-get($material, 'app-bar');
  }

  th.fixed-cell-icon,
  th.fixed-cell-name {
    z-index: 4;
  }

  .v-progress-linear {
    z-index: 5;
  }
}
</style>

<style scoped>
.custom-header-menu-btn {
  opacity: 0.5;
  transition: opacity 0.2s;
}

.custom-header-menu-btn:hover {
  opacity: 1;
}

/* ソートアイコンとの位置調整 */
::v-deep .v-data-table__wrapper th {
  white-space: nowrap;
}

::v-deep .v-data-table-header__icon {
  margin-left: 4px;
}

/* 行をクリック可能にする */
::v-deep .v-data-table__wrapper tbody tr {
  cursor: pointer;
}

::v-deep .v-data-table__wrapper tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

::v-deep .v-data-table__wrapper tbody tr:hover .fixed-cell-icon,
::v-deep .v-data-table__wrapper tbody tr:hover .fixed-cell-name {
  background-color: rgba(0, 0, 0, 0.04) !important;
}
</style>
