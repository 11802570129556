<template>
  <div>
    <div class="d-flex flex-column flex-sm-row align-start align-sm-center justify-space-between mb-6">
      <h1 class="text-h4 mb-4 mb-sm-0">
        ユーザー一覧
      </h1>
      <div class="d-flex align-center w-100 w-sm-auto">
        <v-btn-toggle
          v-model="viewMode"
          mandatory
          class="mr-4"
        >
          <v-btn
            small
            value="grid"
          >
            <v-icon small>
              {{ icons.mdiGrid }}
            </v-icon>
          </v-btn>
          <v-btn
            small
            value="list"
          >
            <v-icon small>
              {{ icons.mdiFormatListBulleted }}
            </v-icon>
          </v-btn>
        </v-btn-toggle>

        <v-btn
          color="primary"
          class="flex-grow-1 flex-sm-grow-0"
          @click="openNewUserDialog"
        >
          <v-icon left>
            {{ icons.mdiPlus }}
          </v-icon>
          ユーザーを追加
        </v-btn>
      </div>
    </div>

    <v-tabs
      v-model="currentTabIdx"
      background-color="transparent"
      class="mb-6"
    >
      <v-tab
        v-for="role in roles"
        :key="`v-tab-${role[0]}`"
      >
        {{ role[1] }}
      </v-tab>
    </v-tabs>

    <div v-if="isLoading">
      <template v-if="viewMode === 'grid'">
        <v-row>
          <v-col
            v-for="loadingIdx of 36"
            :key="`loading${loadingIdx}`"
            md="3"
            sm="3"
            cols="4"
            class="my-2 d-flex align-center justify-center"
          >
            <v-skeleton-loader
              type="avatar"
              size="60"
            />
          </v-col>
        </v-row>
      </template>
      <template v-else>
        <v-skeleton-loader
          type="table"
          class="mb-4"
        />
      </template>
    </div>
    <div v-else>
      <UserGrid
        v-if="viewMode === 'grid'"
        :users="filteredUsers"
        :user-system-flags="userSystemFlags"
        @edit="openEditDialog"
      />
      <UserList
        v-else
        :users="filteredUsers"
        :custom-headers="filteredCustomHeaders"
        :user-system-flags="userSystemFlags"
        @edit="openEditDialog"
        @add-property="openAddPropertyDialog"
        @edit-property="openEditPropertyDialog"
        @delete-property="confirmDeleteProperty"
      />
    </div>

    <PropertyFormDialog
      v-model="newPropertyDialog"
      :role="currentRole"
      @submit="handlePropertyCreate"
    />

    <!-- 新規ユーザー追加ダイアログ -->
    <v-dialog
      v-if="newUserDialog"
      v-model="newUserDialog"
      width="500"
      persistent
    >
      <UserForm
        :role="currentRole"
        :user-system-flags="userSystemFlags"
        :custom-headers="filteredCustomHeaders"
        @submit="handleUserCreate"
        @cancel="newUserDialog = false"
      />
    </v-dialog>

    <!-- ユーザー編集ダイアログ -->
    <v-dialog
      v-if="editDialog && selectedUser"
      v-model="editDialog"
      width="500"
      persistent
    >
      <UserForm
        :user="selectedUser"
        :role="selectedUser.attributes.role"
        :user-system-flags="userSystemFlags"
        :custom-headers="filteredCustomHeaders"
        @submit="handleUserUpdate"
        @delete="handleUserDelete"
        @cancel="handleCancel"
      />
    </v-dialog>

    <PropertyFormDialog
      v-model="editPropertyDialog"
      :property="selectedProperty"
      :role="currentRole"
      @update="handlePropertyUpdate"
    />

    <DeletePropertyConfirm
      v-model="deletePropertyConfirm"
      :property="selectedProperty"
      @confirm="handlePropertyDelete"
    />
  </div>
</template>

<script>
import {
  ref,
  computed,
  getCurrentInstance,
  onMounted,
} from '@vue/composition-api'
import {
  mdiPlus,
  mdiGrid,
  mdiFormatListBulleted,
} from '@mdi/js'
import useCompInit from '@/views/composable/useCompInit'
import useUserPreferences from '@/views/v2-temp/common/composables/useUserPreferences'
import useUsers from './composables/useUsers'
import useUserSystemFlags from './composables/useUserSystemFlags'
import useStoreCustomFields from './composables/useStoreCustomFields'
import UserForm from './components/UserForm.vue'
import UserList from './components/UserList.vue'
import UserGrid from './components/UserGrid.vue'
import PropertyFormDialog from './components/PropertyFormDialog.vue'
import DeletePropertyConfirm from './components/DeletePropertyConfirm.vue'

export default {
  components: {
    UserForm,
    UserList,
    UserGrid,
    PropertyFormDialog,
    DeletePropertyConfirm,
  },
  setup() {
    const vm = getCurrentInstance().proxy
    const { preferences } = useUserPreferences()
    const viewMode = computed({
      get: () => preferences.value['employee_management:users'].viewMode,
      set: value => {
        preferences.value['employee_management:users'].viewMode = value
      },
    })
    const currentTabIdx = ref(0)
    const newPropertyDialog = ref(false)
    const editPropertyDialog = ref(false)
    const selectedProperty = ref(null)
    const deletePropertyConfirm = ref(false)

    const roles = [
      ['cast', 'キャスト'],
      ['waiter', 'スタッフ'],
      ['alliance', 'アライアンス'],
    ]

    const currentRole = computed(() => roles[currentTabIdx.value][0])

    const { initWith, isLoading } = useCompInit()

    const {
      users,
      selectedUser,
      newUserDialog,
      editDialog,
      filteredUsers,
      getUsers,
      createUser,
      updateUser,
      deleteUser,
      openNewUserDialog,
      openEditDialog,
    } = useUsers(vm, currentRole)

    const {
      userSystemFlags,
      getUserSystemFlags,
    } = useUserSystemFlags()

    const {
      filteredCustomHeaders,
      getCustomFields,
      addCustomField,
      updateCustomField,
      deleteCustomField,
    } = useStoreCustomFields(vm, currentRole)

    const openAddPropertyDialog = () => {
      newPropertyDialog.value = true
    }

    const openEditPropertyDialog = property => {
      selectedProperty.value = {
        id: property.value,
        name: property.text,
        type: property.type,
        position: property.position || 0,
      }
      editPropertyDialog.value = true
    }

    const confirmDeleteProperty = property => {
      selectedProperty.value = {
        id: property.value,
        name: property.text,
      }
      deletePropertyConfirm.value = true
    }

    const handlePropertyCreate = async event => {
      const success = await addCustomField(event)
      if (success) {
        newPropertyDialog.value = false
      }
    }

    const handlePropertyUpdate = async event => {
      const success = await updateCustomField(selectedProperty.value.id, event)
      if (success) {
        editPropertyDialog.value = false
      }
    }

    const handlePropertyDelete = async event => {
      const success = await deleteCustomField(selectedProperty.value.id, event)
      if (success) {
        deletePropertyConfirm.value = false
      }
    }

    const handleUserCreate = async event => {
      const success = await createUser(event)
      if (success) {
        await getUserSystemFlags()
        newUserDialog.value = false
      }
    }

    const handleUserUpdate = async event => {
      const success = await updateUser(event)
      if (success) {
        await getUserSystemFlags()
        editDialog.value = false
      }
    }

    const handleUserDelete = async event => {
      const success = await deleteUser(event)
      if (success) {
        editDialog.value = false
      }
    }

    const handleCancel = () => {
      editDialog.value = false
    }

    onMounted(() => {
      initWith([
        getUsers(),
        getUserSystemFlags(),
        getCustomFields(),
      ])
    })

    return {
      viewMode,
      roles,
      currentTabIdx,
      isLoading,
      users,
      userSystemFlags,
      newUserDialog,
      editDialog,
      selectedUser,
      currentRole,
      filteredUsers,
      newPropertyDialog,
      editPropertyDialog,
      selectedProperty,
      deletePropertyConfirm,
      filteredCustomHeaders,
      openNewUserDialog,
      createUser,
      updateUser,
      deleteUser,
      openEditDialog,
      openAddPropertyDialog,
      openEditPropertyDialog,
      confirmDeleteProperty,
      handlePropertyCreate,
      handlePropertyUpdate,
      handlePropertyDelete,
      handleUserCreate,
      handleUserUpdate,
      handleUserDelete,
      handleCancel,

      icons: {
        mdiPlus,
        mdiGrid,
        mdiFormatListBulleted,
      },
    }
  },
}
</script>
