import { ref, computed } from '@vue/composition-api'
import { findIndex } from 'lodash'
import UserApi from '@/api/v2/employee/user-management/User'
import { handleApiError } from '@/utils/errorHandler'

export default function useUsers(vm, currentRole) {
  const users = ref([])
  const selectedUser = ref(null)
  const newUserDialog = ref(false)
  const editDialog = ref(false)

  const filteredUsers = computed(() => {
    return users.value.filter(user => user.attributes.role === currentRole.value)
  })

  const getUsers = async () => {
    const res = await UserApi.getUsers()

    if (res.data.status === 'success') {
      users.value = res.data.data.data
    }
  }

  const createUser = async ({
    name,
    profileImage,
    userSystemFlaggingsAttributes = [],
    userCustomFieldValuesAttributes = [],
    callback,
  }) => {
    try {
      const res = await UserApi.createUser({
        name,
        role: currentRole.value,
        profileImage,
        userSystemFlaggingsAttributes,
        userCustomFieldValuesAttributes,
      })
      if (res.data.status === 'success') {
        users.value = [res.data.data.data, ...users.value]
        vm.$toast.success('ユーザーを新規作成しました')

        return true
      }

      if (res.data.status === 'error') {
        vm.$toast.error('ユーザーの新規作成に失敗しました')
        vm.$toast.error(res.data.message.join('\n'))

        return false
      }

      return false
    } catch (error) {
      handleApiError(error, { $toast: vm.$toast })

      return false
    } finally {
      callback()
    }
  }

  const updateUser = async ({
    id,
    name,
    profileImage,
    userSystemFlaggingsAttributes = [],
    userCustomFieldValuesAttributes = [],
    callback,
  }) => {
    try {
      const res = await UserApi.updateUser({
        id,
        name,
        profileImage,
        userSystemFlaggingsAttributes,
        userCustomFieldValuesAttributes,
      })

      if (res.data.status === 'success') {
        const updatedUser = res.data.data.data
        const userIdx = findIndex(users.value, user => Number(user.id) === Number(updatedUser.id))
        users.value.splice(userIdx, 1, updatedUser)
        vm.$toast.success('ユーザーを更新しました')

        return true
      }
      if (res.data.status === 'error') {
        vm.$toast.error('ユーザーの更新に失敗しました')
        vm.$toast.error(res.data.message.join('\n'))

        return false
      }

      return false
    } catch (error) {
      handleApiError(error, { $toast: vm.$toast })

      return false
    } finally {
      callback()
    }
  }

  const deleteUser = async ({ id, callback }) => {
    try {
      const res = await UserApi.deleteUser(id)

      if (res.data.status === 'success') {
        const userIdx = findIndex(users.value, user => Number(user.id) === Number(id))
        users.value.splice(userIdx, 1)
        vm.$toast.success('ユーザーを削除しました')

        return true
      }

      if (res.data.status === 'error') {
        vm.$toast.error('ユーザーの削除に失敗しました')
        vm.$toast.error(res.data.message.join('\n'))

        return false
      }

      return false
    } catch (error) {
      handleApiError(error, { $toast: vm.$toast })

      return false
    } finally {
      callback()
    }
  }

  const openNewUserDialog = () => {
    newUserDialog.value = true
  }

  const openEditDialog = user => {
    selectedUser.value = user
    editDialog.value = true
  }

  return {
    users,
    selectedUser,
    newUserDialog,
    editDialog,
    filteredUsers,
    getUsers,
    createUser,
    updateUser,
    deleteUser,
    openNewUserDialog,
    openEditDialog,
  }
}
